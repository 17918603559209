@use "sass:math";

@mixin generateLoading($width, $height, $suffix) {
    .lds-ellipsis#{$suffix} {
        display: inline-block;
        position: relative;
        width: $width;
        height: $height;
    }

    .lds-ellipsis#{$suffix} div {
        position: absolute;
        top: math.div($width, 4);
        width: math.div($width, 6.15);
        height: math.div($width, 6.15);
        border-radius: 50%;
        background: $primary;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }

    .lds-ellipsis#{$suffix} div:nth-child(1) {
        left: math.div($width, 10);
        animation: lds-ellipsis1#{$suffix} 0.6s infinite;
    }

    .lds-ellipsis#{$suffix} div:nth-child(2) {
        left: math.div($width, 10);
        animation: lds-ellipsis2#{$suffix} 0.6s infinite;
    }

    .lds-ellipsis#{$suffix} div:nth-child(3) {
        left: math.div($width, 2.5);
        animation: lds-ellipsis2#{$suffix} 0.6s infinite;
    }

    .lds-ellipsis#{$suffix} div:nth-child(4) {
        left: math.div($width, 1.46);
        animation: lds-ellipsis3#{$suffix} 0.6s infinite;
    }
    @keyframes lds-ellipsis1#{$suffix} {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }
    @keyframes lds-ellipsis3#{$suffix} {
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(0);
        }
    }
    @keyframes lds-ellipsis2#{$suffix} {
        0% {
            transform: translate(0, 0);
        }
        100% {
            transform: translate(math.div($width, 3.33), 0);
        }
    }
}

@include generateLoading(80px, 45px, '');
@include generateLoading(54px, 35px, '-input');


tr.loading {
    text-align: center;
}

.input-group input + .status {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 0 10px;
    margin-left: 0 !important;
    border: 1px solid $input-border-color;
    border-radius: 0 $input-border-radius $input-border-radius 0;

    &.loading {
        background: $gray-100;
    }

    &.error {
        border-color: $danger;
        background: rgba($red-100, 0.2);
    }

    &.success {
        border-color: $success;
        background: rgba($green-100, 0.2);
    }
}
