// Body
$body-bg: #fafafa;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$primary: #5068A5;//#526bac;
$gray-150: #f0f0f0;
$gray-250: #E0E1E2;
$white: #FFFFFF;
$positiveBg: #FCFFF5;
$negativeBg: #FFF6F6;
$offWhite: #F9FAFB;
$purple: #861657;
$green: #037754;

// Forms - control
$input-bg: $white;

// Forms - label
$form-label-font-weight: 600;
$form-label-margin-bottom: 0.25rem;

// Tables
$table-striped-bg: rgba(0, 0, 50, 0.02);
