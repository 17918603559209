.autocomplete-suggestions {
    text-align: left;
    cursor: default;
    border: 1px solid $input-border-color;
    border-top: 0;
    background: #fff;
    margin-top: -4px;
    box-shadow: -1px 1px 3px $box-shadow;
    border-radius: 0 0 $input-border-radius $input-border-radius;

    /* core styles should not be changed */
    position: absolute;
    display: none;
    z-index: 9999;
    max-height: 254px;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
}

.autocomplete-suggestion {
    position: relative;
    padding: $input-padding-y $input-padding-x;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $input-color;
}

.autocomplete-suggestion span {
    font-weight: 600;
    color: $primary;
}

.autocomplete-suggestion.selected {
    background: $gray-150;
}

.input-group .form-control.autocomplete {
    border-right: 0 none;
}

.form-control.autocomplete:focus {
    border-color: $input-border-color;
    box-shadow: none;

    &.is-invalid {
        border-color: $form-feedback-invalid-color
    }
}
