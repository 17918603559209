// Variables
@import "variables";

// Bootstrap
@import '~bootstrap-icons/font/bootstrap-icons.css';
%bi {
    display: inline-block;
    font-family: bootstrap-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: -.125em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
%bi-caret-down-fill {
    content: "\f229";
}

@import '~bootstrap/scss/bootstrap';
@import 'flags';
@import 'phone';
@import 'avatar';
@import 'loading';
@import 'autocomplete';
@import 'dropzone';
@import 'sponsors';

body {
    min-width: 360px;
}

#app,
main.guest {
    min-height: 100vh;
}

.tertiary-link {
    color: $gray-600;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.required {
    font-weight: bold;
    color: $red;
    margin-left: 2px;
}

.incognito {
    color: $body-color;
    text-decoration: none;

    &:hover {
        color: $body-color;
        text-decoration: underline;
    }
}

.no-wrap {
    white-space: nowrap !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.cursor-pointer {
    cursor: pointer;
}

img.logo.small {
    height: 60px;
}

.bi {
    &.green {
        color: $green;
    }

    &.orange {
        color: $orange;
    }

    &.gray {
        color: $gray-500;
    }

    &.red {
        color: $red;
    }

    &.fw-bold:before {
        font-weight: 700 !important;
    }
}

.badge.badge-btn {
    @extend .btn;
    cursor: default;
    color: $white;

    &:hover {
        color: $white;
    }
}

.btn-white {
    @include button-variant($white, $white);
}

.btn-light-grey {
    @include button-variant($gray-250, $gray-250);
}

.btn-outline-light-grey {
    @include button-outline-variant($gray-250);
}

.btn-red-hover {
    &:focus,
    &:hover {
        color: color-contrast($danger);
        @include gradient-bg(tint-color($danger, $btn-hover-bg-tint-amount));
        border-color: tint-color($danger, $btn-hover-border-tint-amount);
    }

    &:focus {
        box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-contrast($danger), $danger, 15%), .5);
    }
}

.btn-green-hover {
    &:focus,
    &:hover {
        color: color-contrast($success);
        @include gradient-bg(tint-color($success, $btn-hover-bg-tint-amount));
        border-color: tint-color($success, $btn-hover-border-tint-amount);
    }

    &:focus {
        box-shadow: 0 0 0 $btn-focus-width rgba(mix(color-contrast($success), $success, 15%), .5);
    }
}

.badge.badge-light-grey {
    background-color: $gray-250;
    border-color: $gray-250;
    color: $black;
}

.table-striped {
    > tbody > tr:nth-of-type(even) {
        background: $white;
    }
}

.table tr td.positive {
    background: $positiveBg;
}

.table tr td.negative {
    background: $negativeBg;
}

footer {
    padding-bottom: 3rem;
    color: $secondary;

    a {
        color: $secondary;
        text-decoration: none;
        transition: all 0.2s ease-in-out;

        &:hover {
            color: $primary;
        }
    }

    ul {
        padding: 0;

        li {
            margin: 0 10px;
            list-style: none;

            a {
                display: inline-block;
            }
        }
    }
}

.sub-title {
    @extend %heading;
    @include font-size($h3-font-size);
}

#hands {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3rem;
    background:
        url('/images/hands.png') 0 100% repeat-x,
        url('/images/hands.png') 80px 100% repeat-x;
}

section {
    position: relative;

    &.white {
        background: $white;
    }

    &.divider {
        &.top {
            padding-top: 64px;
        }
        &.bottom {
            padding-bottom: 64px;
        }
    }
}

section.banner {
    --banner-bg: #{$primary};
    background: var(--banner-bg);
    color: $white;
    padding: 94px 0;
}

div > section.banner {
    --banner-bg: #{$primary};
    --banner-bg-next: #{$green};
    --banner-bg-prev: #{$yellow};

    &.odd {
        .tilt.top {
            .shape-fill {
                fill: var(--banner-bg-prev);
            }
        }

        .tilt.bottom {
            .shape-fill {
                fill: var(--banner-bg-next);
            }
        }
    }

    &:not(:last-child).even {
        padding: 31px 0;

        .tilt {
            display: none;
        }
    }

    &:first-child {
        .tilt.top {
            .shape-fill {
                fill: $body-bg;
            }
        }
    }

    &:last-child {
        &.even {
            padding: 31px 0 94px;

            .tilt.top {
                display: none;
            }
        }

        .tilt.bottom {
            .shape-fill {
                fill: $body-bg;
            }
        }
    }
}

div > section.banner:nth-of-type(4n + 2) {
    --banner-bg: #{$green};
    --banner-bg-next: #{$purple};
    --banner-bg-prev: #{$primary};
}

div > section.banner:nth-of-type(4n + 3) {
    --banner-bg: #{$purple};
    --banner-bg-next: #{$yellow};
    --banner-bg-prev: #{$green};
}

div > section.banner:nth-of-type(4n) {
    --banner-bg: #{$yellow};
    --banner-bg-next: #{$primary};
    --banner-bg-prev: #{$purple};
    color: $body-color;
}

.stat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 150px;
    margin: 1rem 0;

    @extend %heading;
    @include font-size($h3-font-size);

    .number {
        @extend %heading;
        @include font-size($h1-font-size);
    }
}

.cta {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 1rem;
    padding: 2rem;
    text-align: center;
    width: 100%;
    max-width: 350px;
    background: var(--cta-bg-color);
    color: var(--cta-text-color);
    border-radius: $border-radius;

    &.js-link {
        cursor: pointer;
    }

    .social {
        margin-top: 1rem;
        padding: 0;

        li {
            display: inline-block;
            margin: 0 1rem;
        }

        a {
            display: inline-block;
            color: $body-color;
            transition: color 0.2s ease-in-out;

            &:hover,
            &:focus {
                color: $white;
            }
        }

        .bi {
            font-size: 40px;
        }
    }

    .text {
        @extend %heading;
        @include font-size($h3-font-size);
        flex: 1;
        margin-bottom: 0;
        align-self: center;
        font-weight: 600;
    }

    .btn {
        margin-top: 1rem;
        padding: 0.5rem 1rem;
        font-size: 1.2rem;
        font-weight: bold;
        transition: color 0.2s ease-in-out,
                    background-color 0.2s ease-in-out;
        border: 5px solid $white;
        color: var(--cta-btn-text-color);

        &:hover,
        &:focus {
            background: var(--cta-hover-bg-color);
            color: var(--cta-text-hover-color);
        }
    }

    &.green {
        --cta-bg-color: #{$green};
        --cta-hover-bg-color: #{$green};
        --cta-text-color: #{$white};
        --cta-btn-text-color: #{$green};
        --cta-text-hover-color: #{$white};
    }

    &.purple {
        --cta-bg-color: #{$purple};
        --cta-hover-bg-color: #{$purple};
        --cta-text-color: #{$white};
        --cta-btn-text-color: #{$purple};
        --cta-text-hover-color: #{$white};
    }

    &.yellow {
        --cta-bg-color: #{$yellow};
        --cta-text-color: #{$body-color};
        --cta-btn-text-color: #{$body-color};
        --cta-hover-bg-color: #{$body-color};
        --cta-text-hover-color: #{$yellow};
    }
}

.payment-method {
    border: 2px solid $gray-250;
    border-radius: 1rem;
    padding: 0.5rem;
    cursor: pointer;
    transition: border-color ease-in-out 0.2s;
    position: relative;

    &:after {
        position: absolute;
        top: 0;
        right: 0;
        display: inline-block;
        width: 25px;
        height: 25px;
        background: $primary;
        border-radius: 0 10px 0 0;
        padding: 2px 5px 0 2px;
        content: "\f26e";
        color: $white;
        font-display: block;
        font-family: bootstrap-icons !important;
        font-size: 20px;
        font-style: normal;
        font-weight: normal !important;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        vertical-align: -.125em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transition: opacity ease-in-out 0.2s;
        opacity: 0;
    }

    img {
        display: block;
        max-width: 100px;
    }
}

input:checked + .payment-method {
    border-color: $primary;

    &:after {
        opacity: 1;
    }
}

#overview {
    .card {
        width: 250px;
        margin: 0.5rem;
    }
}

@include media-breakpoint-up(md) {
    table .pagination {
        justify-content: center;
    }
}

@include media-breakpoint-up(lg) {
    .correct-center {
        margin-bottom: 8rem;
    }

    .cta {
        flex: 1;
    }
}

.ck-content .row {
    margin-left: 0;
    margin-right: 0;
}

.body,
.ck-content {
    img {
        height: auto;
        max-width: 100%
    }

    .image-style-align-left {
        float: left;
    }

    .image-style-align-right {
        float: right;
    }

    .image-style-align-center {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}

.tilt {
    position: absolute;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;

    svg {
        position: relative;
        display: block;
        width: calc(142% + 1.3px);
        height: 56px;
    }

    .shape-fill {
        fill: $body-bg;
    }

    &.top {
        top: 0;
    }

    &.bottom {
        bottom: 0;
        transform: rotate(180deg);

        &:not(.inverse) svg {
            transform: rotateY(180deg);
        }
    }

    &.white .shape-fill {
        fill: $white;
    }
}

.main {
    position: relative;
    background: $white;
    padding-bottom: 64px;

    .nav-item a {
        color: $body-color;
        transition: color 100ms ease-in-out;

        &:hover {
            color: $primary;
        }
    }
}

@include media-breakpoint-down(md) {
    .navbar-nav {
        .nav-link {
            text-align: center;
        }
    }
}

#participant-list .participant {
    margin-top: 1.5rem;
}
