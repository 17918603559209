$flagsImagePath: '/images/';
$arrowColor: #000000;
$hoverColor: #E9ECEF;
$flagWidth: 20px;
$flagHeight: 11px;


// NOTE: by using !default on all variables, we're saying only declare the variable if it doesn't
// already exist, which allows devs to declare these variables themselves and assign them any value
// they want before importing this file

// rgba is needed for the selected flag hover state to blend in with
// the border-highlighting some browsers give the input on focus
$hoverColor: rgba(0, 0, 0, 0.05) !default;
$greyText: #999 !default;
$greyBorder: #CCC !default;

$flagHeight: 15px !default;
$flagWidth: 20px !default;
$flagPadding: 8px !default;
// this border width is used for the popup and divider, but it is also
// assumed to be the border width of the input, which we do not control
$borderWidth: 1px !default;

$arrowHeight: 4px !default;
$arrowWidth: 6px !default;
$triangleBorder: 3px !default;
$arrowPadding: 6px !default;
$arrowColor: #555 !default;

$inputPadding: 6px !default;
$selectedFlagWidth: $flagWidth + (2 * $flagPadding) !default;
$selectedFlagArrowWidth: $flagWidth + $flagPadding + $arrowWidth + (2 * $arrowPadding) !default;

// image related variables
$flagsImagePath: "../img/" !default;
$flagsImageName: "flags" !default;
$flagsImageExtension: "png" !default;

// enough space for them to click off to close
$mobilePopupMargin: 30px !default;

.iti {
    // need position on the container so the selected flag can be
    // absolutely positioned over the input
    position: relative;
    // keep the input's default inline properties
    display: inline-block;

    // paul irish says this is ok
    // http://www.paulirish.com/2012/box-sizing-border-box-ftw/
    * {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
    }

    &__hide {
        display: none;
    }
    // need this during init, to get the height of the dropdown
    &__v-hide {
        visibility: hidden;
    }

    // specify types to increase specificity e.g. to override bootstrap v2.3
    input, input[type=text], input[type=tel] {
        position: relative;
        // input is bottom level, below selected flag and dropdown
        z-index: 0;

        // any vertical margin the user has on their inputs would no longer work as expected
        // because we wrap everything in a container div. i justify the use of !important
        // here because i don't think the user should ever have vertical margin here - when
        // the input is wrapped in a container, vertical margin messes up alignment with other
        // inline elements (e.g. an adjacent button) in firefox, and probably other browsers.
        margin-top: 0 ;
        margin-bottom: 0 ;

        // make space for the selected flag on right of input (if disabled allowDropdown)
        // Note: no !important here, as the user may want to tweak this so that the
        // perceived input padding matches their existing styles
        padding-right: $selectedFlagWidth;

        // any margin-right here will push the selected-flag away
        margin-right: 0;
    }

    &__flag-container {
        // positioned over the top of the input
        position: absolute;
        // full height
        top: 0;
        bottom: 0;
        right: 0;
        // prevent the highlighted child from overlapping the input border
        padding: $borderWidth;
    }

    &__selected-flag {
        // render above the input
        z-index: 1;
        position: relative;
        display: flex;
        align-items: center;
        // this must be full-height both for the hover highlight, and to push down the
        // dropdown so it appears below the input
        height: 100%;
        padding: 0 $arrowPadding 0 $flagPadding;
    }

    &__arrow {
        margin-left: $arrowPadding;

        // css triangle
        width: 0;
        height: 0;
        border-left: $triangleBorder solid transparent;
        border-right: $triangleBorder solid transparent;
        border-top: $arrowHeight solid $arrowColor;

        &--up {
            border-top: none;
            border-bottom: $arrowHeight solid $arrowColor;
        }
    }

    // the dropdown
    &__country-list {
        position: absolute;
        // popup so render above everything else
        z-index: 2;

        // override default list styles
        list-style: none;
        // in case any container has text-align:center
        text-align: left;

        // place menu above the input element
        &--dropup {
            bottom: 100%;
            margin-bottom: (-$borderWidth);
        }

        padding: 0;
        // margin-left to compensate for the padding on the parent
        margin: 0 0 0 (-$borderWidth);

        box-shadow: 1px 1px 4px rgba(0,0,0,0.2);
        background-color: white;
        border: $borderWidth solid $greyBorder;

        // don't let the contents wrap AKA the container will be as wide as the contents
        white-space: nowrap;
        // except on small screens, where we force the dropdown width to match the input
        @media (max-width: 500px) {
            white-space: normal;
        }

        max-height: 200px;
        overflow-y: scroll;

        // Fixes https://github.com/jackocnr/intl-tel-input/issues/765
        // Apple still hasn't fixed the issue where setting overflow: scroll on a div element does not use inertia scrolling
        // If this is not set, then the country list scroll stops moving after rasing a finger, and users report that scroll is slow
        // Stackoverflow question about it: https://stackoverflow.com/questions/33601165/scrolling-slow-on-mobile-ios-when-using-overflowscroll
        -webkit-overflow-scrolling: touch;
    }

    // dropdown flags need consistent width, so wrap in a container
    &__flag-box {
        display: inline-block;
        width: $flagWidth;
    }

    // the divider below the preferred countries
    &__divider {
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: $borderWidth solid $greyBorder;
    }

    // each country item in dropdown (we must have separate class to differentiate from dividers)
    &__country {
        // Note: decided not to use line-height here for alignment because it causes issues e.g. large font-sizes will overlap, and also looks bad if one country overflows onto 2 lines
        padding: 5px 10px;
        outline: none;
    }

    // the dial codes after the country names are greyed out
    &__dial-code {
        color: $greyText;
    }
    &__country.iti__highlight {
        background-color: $hoverColor;
    }

    // spacing between country flag, name and dial code
    &__flag-box, &__country-name, &__dial-code {
        vertical-align: middle;
    }
    &__flag-box, &__country-name {
        margin-right: 6px;
    }

    // these settings are independent of each other, but both move selected flag to left of input
    &--allow-dropdown, &--separate-dial-code {
        input, input[type=text], input[type=tel] {
            padding-right: $inputPadding;
            padding-left: $selectedFlagArrowWidth + $inputPadding;
            margin-left: 0;
        }
        .iti__flag-container {
            right: auto;
            left: 0;
        }
    }

    &--allow-dropdown {
        // hover state - show flag is clickable
        .iti__flag-container:hover {
            cursor: pointer;
            .iti__selected-flag {
                background-color: $hoverColor;
            }
        }
        // disable hover state when input is disabled
        input[disabled] + .iti__flag-container:hover,
        input[readonly] + .iti__flag-container:hover {
            cursor: default;
            .iti__selected-flag {
                background-color: transparent;
            }
        }
    }

    &--separate-dial-code {
        .iti__selected-flag {
            // now that we have digits in this section, it needs this visual separation
            background-color: $hoverColor;
        }
        .iti__selected-dial-code {
            margin-left: $arrowPadding;
        }
    }

    // if dropdownContainer option is set, increase z-index to prevent display issues
    &--container {
        position: absolute;
        top: -1000px;
        left: -1000px;
        // higher than default Bootstrap modal z-index of 1050
        z-index: 1060;
        // to keep styling consistent with .flag-container
        padding: $borderWidth;
        &:hover {
            cursor: pointer;
        }
    }
}

// overrides for mobile popup (note: .iti-mobile class is applied on body)
.iti-mobile .iti {
    &--container {
        top: $mobilePopupMargin;
        bottom: $mobilePopupMargin;
        left: $mobilePopupMargin;
        right: $mobilePopupMargin;
        position: fixed;
    }
    &__country-list {
        max-height: 100%;
        width: 100%;
    }
    &__country {
        padding: 10px 10px;
        // increase line height because dropdown copy is v likely to overflow on mobile and when it does it needs to be well spaced
        line-height: 1.5em;
    }
}




// ################################
// ################################
// ######### Overrides ############
// ################################
// ################################

.iti {
    width: 100%;
    display: block;

    .iti__flag-container {
        width: 100%;

        .iti__selected-flag {
            width: 50px;
            background: $hoverColor;
            outline: none;

            .iti__arrow {
                border: 0 none;
                margin: -14px 0 0 8px;
                font-size: 12px;

                @extend %bi;

                &:before {
                    @extend %bi-caret-down-fill;
                }
            }
        }

        .iti__country-list {
            z-index: 11;
            position: relative;
            overflow-x: hidden;
            width: calc(100% + 2px);
            max-height: 191px;
        }
    }

    input {
        position: relative;
        padding-left: 55px !important;
    }
}

.iti__flag {
    @extend %flag;
}

.iti__ad/*,
.iti__andorra*/ {
    background-position: 0 0;
}
.iti__ae/*,
.iti__united.arab.emirates/*,
.iti__uae*/ {
    background-position: 0 -26px;
}
.iti__af/*,
.iti__afghanistan*/ {
    background-position: 0 -52px;
}
.iti__ag/*,
.iti__antigua*/ {
    background-position: 0 -78px;
}
.iti__ai/*,
.iti__anguilla*/ {
    background-position: 0 -104px;
}
.iti__al/*,
.iti__albania*/ {
    background-position: 0 -130px;
}
.iti__am/*,
.iti__armenia*/ {
    background-position: 0 -156px;
}
.iti__an/*,
.iti__netherlands.antilles*/ {
    background-position: 0 -182px;
}
.iti__ao/*,
.iti__angola*/ {
    background-position: 0 -208px;
}
.iti__ar/*,
.iti__argentina*/ {
    background-position: 0 -234px;
}
.iti__as/*,
.iti__american.samoa*/ {
    background-position: 0 -260px;
}
.iti__at/*,
.iti__austria*/ {
    background-position: 0 -286px;
}
.iti__au/*,
.iti__australia*/ {
    background-position: 0 -312px;
}
.iti__aw/*,
.iti__aruba*/ {
    background-position: 0 -338px;
}
.iti__ax/*,
.iti__aland.islands*/ {
    background-position: 0 -364px;
}
.iti__az/*,
.iti__azerbaijan*/ {
    background-position: 0 -390px;
}
.iti__ba/*,
.iti__bosnia*/ {
    background-position: 0 -416px;
}
.iti__bb/*,
.iti__barbados*/ {
    background-position: 0 -442px;
}
.iti__bd/*,
.iti__bangladesh*/ {
    background-position: 0 -468px;
}
.iti__be/*,
.iti__belgium*/ {
    background-position: 0 -494px;
}
.iti__bf/*,
.iti__burkina.faso*/ {
    background-position: 0 -520px;
}
.iti__bg/*,
.iti__bulgaria*/ {
    background-position: 0 -546px;
}
.iti__bh/*,
.iti__bahrain*/ {
    background-position: 0 -572px;
}
.iti__bi/*,
.iti__burundi*/ {
    background-position: 0 -598px;
}
.iti__bj/*,
.iti__benin*/ {
    background-position: 0 -624px;
}
.iti__bm/*,
.iti__bermuda*/ {
    background-position: 0 -650px;
}
.iti__bn/*,
.iti__brunei*/ {
    background-position: 0 -676px;
}
.iti__bo/*,
.iti__bolivia*/ {
    background-position: 0 -702px;
}
.iti__br/*,
.iti__brazil*/ {
    background-position: 0 -728px;
}
.iti__bs/*,
.iti__bahamas*/ {
    background-position: 0 -754px;
}
.iti__bt/*,
.iti__bhutan*/ {
    background-position: 0 -780px;
}
.iti__bv/*,
.iti__bouvet.island*/ {
    background-position: 0 -806px;
}
.iti__bw/*,
.iti__botswana*/ {
    background-position: 0 -832px;
}
.iti__by/*,
.iti__belarus*/ {
    background-position: 0 -858px;
}
.iti__bz/*,
.iti__belize*/ {
    background-position: 0 -884px;
}
.iti__ca/*,
.iti__canada*/ {
    background-position: 0 -910px;
}
.iti__cc/*,
.iti__cocos.islands*/ {
    background-position: 0 -962px;
}
.iti__cd/*,
.iti__congo*/ {
    background-position: 0 -988px;
}
.iti__cf/*,
.iti__central.african.republic*/ {
    background-position: 0 -1014px;
}
.iti__cg/*,
.iti__congo.brazzaville*/ {
    background-position: 0 -1040px;
}
.iti__ch/*,
.iti__switzerland*/ {
    background-position: 0 -1066px;
}
.iti__ci/*,
.iti__cote.divoire*/ {
    background-position: 0 -1092px;
}
.iti__ck/*,
.iti__cook.islands*/ {
    background-position: 0 -1118px;
}
.iti__cl/*,
.iti__chile*/ {
    background-position: 0 -1144px;
}
.iti__cm/*,
.iti__cameroon*/ {
    background-position: 0 -1170px;
}
.iti__cn/*,
.iti__china*/ {
    background-position: 0 -1196px;
}
.iti__co/*,
.iti__colombia*/ {
    background-position: 0 -1222px;
}
.iti__cr/*,
.iti__costa.rica*/ {
    background-position: 0 -1248px;
}
.iti__cs/*,
.iti__serbia*/ {
    background-position: 0 -1274px;
}
.iti__cu/*,
.iti__cuba*/ {
    background-position: 0 -1300px;
}
.iti__cv/*,
.iti__cape.verde*/ {
    background-position: 0 -1326px;
}
.iti__cx/*,
.iti__christmas.island*/ {
    background-position: 0 -1352px;
}
.iti__cy/*,
.iti__cyprus*/ {
    background-position: 0 -1378px;
}
.iti__cz/*,
.iti__czech.republic*/ {
    background-position: 0 -1404px;
}
.iti__de/*,
.iti__germany*/ {
    background-position: 0 -1430px;
}
.iti__dj/*,
.iti__djibouti*/ {
    background-position: 0 -1456px;
}
.iti__dk/*,
.iti__denmark*/ {
    background-position: 0 -1482px;
}
.iti__dm/*,
.iti__dominica*/ {
    background-position: 0 -1508px;
}
.iti__do/*,
.iti__dominican.republic*/ {
    background-position: 0 -1534px;
}
.iti__dz/*,
.iti__algeria*/ {
    background-position: 0 -1560px;
}
.iti__ec/*,
.iti__ecuador*/ {
    background-position: 0 -1586px;
}
.iti__ee/*,
.iti__estonia*/ {
    background-position: 0 -1612px;
}
.iti__eg/*,
.iti__egypt*/ {
    background-position: 0 -1638px;
}
.iti__eh/*,
.iti__western.sahara*/ {
    background-position: 0 -1664px;
}
.iti__er/*,
.iti__eritrea*/ {
    background-position: 0 -1716px;
}
.iti__es/*,
.iti__spain*/ {
    background-position: 0 -1742px;
}
.iti__et/*,
.iti__ethiopia*/ {
    background-position: 0 -1768px;
}
.iti__eu/*,
.iti__european.union*/ {
    background-position: 0 -1794px;
}
.iti__fi/*,
.iti__finland*/ {
    background-position: 0 -1846px;
}
.iti__fj/*,
.iti__fiji*/ {
    background-position: 0 -1872px;
}
.iti__fk/*,
.iti__falkland.islands*/ {
    background-position: 0 -1898px;
}
.iti__fm/*,
.iti__micronesia*/ {
    background-position: 0 -1924px;
}
.iti__fo/*,
.iti__faroe.islands*/ {
    background-position: 0 -1950px;
}
.iti__fr/*,
.iti__france*/ {
    background-position: 0 -1976px;
}
.iti__ga/*,
.iti__gabon*/ {
    background-position: -36px 0;
}
.iti__gb/*,
.iti__united.kingdom*/ {
    background-position: -36px -26px;
}
.iti__gd/*,
.iti__grenada*/ {
    background-position: -36px -52px;
}
.iti__ge/*,
.iti__georgia*/ {
    background-position: -36px -78px;
}
.iti__gf/*,
.iti__french.guiana*/ {
    background-position: -36px -104px;
}
.iti__gh/*,
.iti__ghana*/ {
    background-position: -36px -130px;
}
.iti__gi/*,
.iti__gibraltar*/ {
    background-position: -36px -156px;
}
.iti__gl/*,
.iti__greenland*/ {
    background-position: -36px -182px;
}
.iti__gm/*,
.iti__gambia*/ {
    background-position: -36px -208px;
}
.iti__gn/*,
.iti__guinea*/ {
    background-position: -36px -234px;
}
.iti__gp/*,
.iti__guadeloupe*/ {
    background-position: -36px -260px;
}
.iti__gq/*,
.iti__equatorial.guinea*/ {
    background-position: -36px -286px;
}
.iti__gr/*,
.iti__greece*/ {
    background-position: -36px -312px;
}
.iti__gs/*,
.iti__sandwich.islands*/ {
    background-position: -36px -338px;
}
.iti__gt/*,
.iti__guatemala*/ {
    background-position: -36px -364px;
}
.iti__gu/*,
.iti__guam*/ {
    background-position: -36px -390px;
}
.iti__gw/*,
.iti__guinea-bissau*/ {
    background-position: -36px -416px;
}
.iti__gy/*,
.iti__guyana*/ {
    background-position: -36px -442px;
}
.iti__hk/*,
.iti__hong.kong*/ {
    background-position: -36px -468px;
}
.iti__hm/*,
.iti__heard.island*/ {
    background-position: -36px -494px;
}
.iti__hn/*,
.iti__honduras*/ {
    background-position: -36px -520px;
}
.iti__hr/*,
.iti__croatia*/ {
    background-position: -36px -546px;
}
.iti__ht/*,
.iti__haiti*/ {
    background-position: -36px -572px;
}
.iti__hu/*,
.iti__hungary*/ {
    background-position: -36px -598px;
}
.iti__id/*,
.iti__indonesia*/ {
    background-position: -36px -624px;
}
.iti__ie/*,
.iti__ireland*/ {
    background-position: -36px -650px;
}
.iti__il/*,
.iti__israel*/ {
    background-position: -36px -676px;
}
.iti__in/*,
.iti__india*/ {
    background-position: -36px -702px;
}
.iti__io/*,
.iti__indian.ocean.territory*/ {
    background-position: -36px -728px;
}
.iti__iq/*,
.iti__iraq*/ {
    background-position: -36px -754px;
}
.iti__ir/*,
.iti__iran*/ {
    background-position: -36px -780px;
}
.iti__is/*,
.iti__iceland*/ {
    background-position: -36px -806px;
}
.iti__it/*,
.iti__italy*/ {
    background-position: -36px -832px;
}
.iti__jm/*,
.iti__jamaica*/ {
    background-position: -36px -858px;
}
.iti__jo/*,
.iti__jordan*/ {
    background-position: -36px -884px;
}
.iti__jp/*,
.iti__japan*/ {
    background-position: -36px -910px;
}
.iti__ke/*,
.iti__kenya*/ {
    background-position: -36px -936px;
}
.iti__kg/*,
.iti__kyrgyzstan*/ {
    background-position: -36px -962px;
}
.iti__kh/*,
.iti__cambodia*/ {
    background-position: -36px -988px;
}
.iti__ki/*,
.iti__kiribati*/ {
    background-position: -36px -1014px;
}
.iti__km/*,
.iti__comoros*/ {
    background-position: -36px -1040px;
}
.iti__kn/*,
.iti__saint.kitts.and.nevis*/ {
    background-position: -36px -1066px;
}
.iti__kp/*,
.iti__north.korea*/ {
    background-position: -36px -1092px;
}
.iti__kr/*,
.iti__south.korea*/ {
    background-position: -36px -1118px;
}
.iti__kw/*,
.iti__kuwait*/ {
    background-position: -36px -1144px;
}
.iti__ky/*,
.iti__cayman.islands*/ {
    background-position: -36px -1170px;
}
.iti__kz/*,
.iti__kazakhstan*/ {
    background-position: -36px -1196px;
}
.iti__la/*,
.iti__laos*/ {
    background-position: -36px -1222px;
}
.iti__lb/*,
.iti__lebanon*/ {
    background-position: -36px -1248px;
}
.iti__lc/*,
.iti__saint.lucia*/ {
    background-position: -36px -1274px;
}
.iti__li/*,
.iti__liechtenstein*/ {
    background-position: -36px -1300px;
}
.iti__lk/*,
.iti__sri.lanka*/ {
    background-position: -36px -1326px;
}
.iti__lr/*,
.iti__liberia*/ {
    background-position: -36px -1352px;
}
.iti__ls/*,
.iti__lesotho*/ {
    background-position: -36px -1378px;
}
.iti__lt/*,
.iti__lithuania*/ {
    background-position: -36px -1404px;
}
.iti__lu/*,
.iti__luxembourg*/ {
    background-position: -36px -1430px;
}
.iti__lv/*,
.iti__latvia*/ {
    background-position: -36px -1456px;
}
.iti__ly/*,
.iti__libya*/ {
    background-position: -36px -1482px;
}
.iti__ma/*,
.iti__morocco*/ {
    background-position: -36px -1508px;
}
.iti__mc/*,
.iti__monaco*/ {
    background-position: -36px -1534px;
}
.iti__md/*,
.iti__moldova*/ {
    background-position: -36px -1560px;
}
.iti__me/*,
.iti__montenegro*/ {
    background-position: -36px -1586px;
}
.iti__mg/*,
.iti__madagascar*/ {
    background-position: -36px -1613px;
}
.iti__mh/*,
.iti__marshall.islands*/ {
    background-position: -36px -1639px;
}
.iti__mk/*,
.iti__macedonia*/ {
    background-position: -36px -1665px;
}
.iti__ml/*,
.iti__mali*/ {
    background-position: -36px -1691px;
}
.iti__mm/*,
.iti__myanmar/*,
.iti__burma*/ {
    background-position: -36px -1717px;
}
.iti__mn/*,
.iti__mongolia*/ {
    background-position: -36px -1743px;
}
.iti__mo/*,
.iti__macau*/ {
    background-position: -36px -1769px;
}
.iti__mp/*,
.iti__northern.mariana.islands*/ {
    background-position: -36px -1795px;
}
.iti__mq/*,
.iti__martinique*/ {
    background-position: -36px -1821px;
}
.iti__mr/*,
.iti__mauritania*/ {
    background-position: -36px -1847px;
}
.iti__ms/*,
.iti__montserrat*/ {
    background-position: -36px -1873px;
}
.iti__mt/*,
.iti__malta*/ {
    background-position: -36px -1899px;
}
.iti__mu/*,
.iti__mauritius*/ {
    background-position: -36px -1925px;
}
.iti__mv/*,
.iti__maldives*/ {
    background-position: -36px -1951px;
}
.iti__mw/*,
.iti__malawi*/ {
    background-position: -36px -1977px;
}
.iti__mx/*,
.iti__mexico*/ {
    background-position: -72px 0;
}
.iti__my/*,
.iti__malaysia*/ {
    background-position: -72px -26px;
}
.iti__mz/*,
.iti__mozambique*/ {
    background-position: -72px -52px;
}
.iti__na/*,
.iti__namibia*/ {
    background-position: -72px -78px;
}
.iti__nc/*,
.iti__new.caledonia*/ {
    background-position: -72px -104px;
}
.iti__ne/*,
.iti__niger*/ {
    background-position: -72px -130px;
}
.iti__nf/*,
.iti__norfolk.island*/ {
    background-position: -72px -156px;
}
.iti__ng/*,
.iti__nigeria*/ {
    background-position: -72px -182px;
}
.iti__ni/*,
.iti__nicaragua*/ {
    background-position: -72px -208px;
}
.iti__nl/*,
.iti__netherlands*/ {
    background-position: -72px -234px;
}
.iti__no/*,
.iti__norway*/ {
    background-position: -72px -260px;
}
.iti__np/*,
.iti__nepal*/ {
    background-position: -72px -286px;
}
.iti__nr/*,
.iti__nauru*/ {
    background-position: -72px -312px;
}
.iti__nu/*,
.iti__niue*/ {
    background-position: -72px -338px;
}
.iti__nz/*,
.iti__new.zealand*/ {
    background-position: -72px -364px;
}
.iti__om/*,
.iti__oman*/ {
    background-position: -72px -390px;
}
.iti__pa/*,
.iti__panama*/ {
    background-position: -72px -416px;
}
.iti__pe/*,
.iti__peru*/ {
    background-position: -72px -442px;
}
.iti__pf/*,
.iti__french.polynesia*/ {
    background-position: -72px -468px;
}
.iti__pg/*,
.iti__new.guinea*/ {
    background-position: -72px -494px;
}
.iti__ph/*,
.iti__philippines*/ {
    background-position: -72px -520px;
}
.iti__pk/*,
.iti__pakistan*/ {
    background-position: -72px -546px;
}
.iti__pl/*,
.iti__poland*/ {
    background-position: -72px -572px;
}
.iti__pm/*,
.iti__saint.pierre*/ {
    background-position: -72px -598px;
}
.iti__pn/*,
.iti__pitcairn.islands*/ {
    background-position: -72px -624px;
}
.iti__pr/*,
.iti__puerto.rico*/ {
    background-position: -72px -650px;
}
.iti__ps/*,
.iti__palestine*/ {
    background-position: -72px -676px;
}
.iti__pt/*,
.iti__portugal*/ {
    background-position: -72px -702px;
}
.iti__pw/*,
.iti__palau*/ {
    background-position: -72px -728px;
}
.iti__py/*,
.iti__paraguay*/ {
    background-position: -72px -754px;
}
.iti__qa/*,
.iti__qatar*/ {
    background-position: -72px -780px;
}
.iti__re/*,
.iti__reunion*/ {
    background-position: -72px -806px;
}
.iti__ro/*,
.iti__romania*/ {
    background-position: -72px -832px;
}
.iti__rs/*,
.iti__serbia*/ {
    background-position: -72px -858px;
}
.iti__ru/*,
.iti__russia*/ {
    background-position: -72px -884px;
}
.iti__rw/*,
.iti__rwanda*/ {
    background-position: -72px -910px;
}
.iti__sa/*,
.iti__saudi.arabia*/ {
    background-position: -72px -936px;
}
.iti__sb/*,
.iti__solomon.islands*/ {
    background-position: -72px -962px;
}
.iti__sc/*,
.iti__seychelles*/ {
    background-position: -72px -988px;
}
.iti__sd/*,
.iti__sudan*/ {
    background-position: -72px -1040px;
}
.iti__se/*,
.iti__sweden*/ {
    background-position: -72px -1066px;
}
.iti__sg/*,
.iti__singapore*/ {
    background-position: -72px -1092px;
}
.iti__sh/*,
.iti__saint.helena*/ {
    background-position: -72px -1118px;
}
.iti__si/*,
.iti__slovenia*/ {
    background-position: -72px -1144px;
}
.iti__sj/*,
.iti__svalbard/*,
.iti__jan.mayen*/ {
    background-position: -72px -1170px;
}
.iti__sk/*,
.iti__slovakia*/ {
    background-position: -72px -1196px;
}
.iti__sl/*,
.iti__sierra.leone*/ {
    background-position: -72px -1222px;
}
.iti__sm/*,
.iti__san.marino*/ {
    background-position: -72px -1248px;
}
.iti__sn/*,
.iti__senegal*/ {
    background-position: -72px -1274px;
}
.iti__so/*,
.iti__somalia*/ {
    background-position: -72px -1300px;
}
.iti__sr/*,
.iti__suriname*/ {
    background-position: -72px -1326px;
}
.iti__st/*,
.iti__sao.tome*/ {
    background-position: -72px -1352px;
}
.iti__sv/*,
.iti__el.salvador*/ {
    background-position: -72px -1378px;
}
.iti__sy/*,
.iti__syria*/ {
    background-position: -72px -1404px;
}
.iti__sz/*,
.iti__swaziland*/ {
    background-position: -72px -1430px;
}
.iti__tc/*,
.iti__caicos.islands*/ {
    background-position: -72px -1456px;
}
.iti__td/*,
.iti__chad*/ {
    background-position: -72px -1482px;
}
.iti__tf/*,
.iti__french.territories*/ {
    background-position: -72px -1508px;
}
.iti__tg/*,
.iti__togo*/ {
    background-position: -72px -1534px;
}
.iti__th/*,
.iti__thailand*/ {
    background-position: -72px -1560px;
}
.iti__tj/*,
.iti__tajikistan*/ {
    background-position: -72px -1586px;
}
.iti__tk/*,
.iti__tokelau*/ {
    background-position: -72px -1612px;
}
.iti__tl/*,
.iti__timorleste*/ {
    background-position: -72px -1638px;
}
.iti__tm/*,
.iti__turkmenistan*/ {
    background-position: -72px -1664px;
}
.iti__tn/*,
.iti__tunisia*/ {
    background-position: -72px -1690px;
}
.iti__to/*,
.iti__tonga*/ {
    background-position: -72px -1716px;
}
.iti__tr/*,
.iti__turkey*/ {
    background-position: -72px -1742px;
}
.iti__tt/*,
.iti__trinidad*/ {
    background-position: -72px -1768px;
}
.iti__tv/*,
.iti__tuvalu*/ {
    background-position: -72px -1794px;
}
.iti__tw/*,
.iti__taiwan*/ {
    background-position: -72px -1820px;
}
.iti__tz/*,
.iti__tanzania*/ {
    background-position: -72px -1846px;
}
.iti__ua/*,
.iti__ukraine*/ {
    background-position: -72px -1872px;
}
.iti__ug/*,
.iti__uganda*/ {
    background-position: -72px -1898px;
}
.iti__um/*,
.iti__us.minor.islands*/ {
    background-position: -72px -1924px;
}
.iti__us/*,
.iti__america/*,
.iti__united.states*/ {
    background-position: -72px -1950px;
}
.iti__uy/*,
.iti__uruguay*/ {
    background-position: -72px -1976px;
}
.iti__uz/*,
.iti__uzbekistan*/ {
    background-position: -108px 0;
}
.iti__va/*,
.iti__vatican.city*/ {
    background-position: -108px -26px;
}
.iti__vc/*,
.iti__saint.vincent*/ {
    background-position: -108px -52px;
}
.iti__ve/*,
.iti__venezuela*/ {
    background-position: -108px -78px;
}
.iti__vg/*,
.iti__british.virgin.islands*/ {
    background-position: -108px -104px;
}
.iti__vi/*,
.iti__us.virgin.islands*/ {
    background-position: -108px -130px;
}
.iti__vn/*,
.iti__vietnam*/ {
    background-position: -108px -156px;
}
.iti__vu/*,
.iti__vanuatu*/ {
    background-position: -108px -182px;
}
.iti__wf/*,
.iti__wallis.and.futuna*/ {
    background-position: -108px -234px;
}
.iti__ws/*,
.iti__samoa*/ {
    background-position: -108px -260px;
}
.iti__ye/*,
.iti__yemen*/ {
    background-position: -108px -286px;
}
.iti__yt/*,
.iti__mayotte*/ {
    background-position: -108px -312px;
}
.iti__za/*,
.iti__south.africa*/ {
    background-position: -108px -338px;
}
.iti__zm/*,
.iti__zambia*/ {
    background-position: -108px -364px;
}
.iti__zw/*,
.iti__zimbabwe*/ {
    background-position: -108px -390px;
}
