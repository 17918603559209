.avatar {
    border-radius: 100%;
    max-width: 100%;
    aspect-ratio: 1;
    object-fit: cover;

    &.tiny {
        width: 20px;
        display: inline-block;
        vertical-align: middle;
    }

    &.mini {
        width: 50px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
    }

    &.small {
        width: 80px;
        display: inline-block;
        vertical-align: middle;
    }

    &.medium {
        min-width: 120px;
        max-width: 120px;
    }

    &.large {
        min-width: 120px;
        max-width: 200px;
    }
}
