.sponsors {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .sponsor {
        margin: 1rem;

        img {
            mix-blend-mode: multiply;
        }
    }
}
